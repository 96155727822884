import React from 'react'
import { Parallax } from 'react-scroll-parallax'

export default function TitleImgSection({ data }) {
  const ParallaxImage = () => (
    <Parallax y={[-20, 20]} tagOuter="figure">
      <img src={data.img} alt={data.title} />
    </Parallax>
  )

  return (
    <section className="section">
      <div className="title-w-img">
        <div className="title-w-img__img" style={{ zIndex: 0 }}>
          <ParallaxImage />
        </div>
        <div className="title-w-img__gradient" />
        <div className="title-w-img__inner-wrap">
          <div className="title-w-img__inner">
            <h2 className="title-w-img__title">{data.title}</h2>
            {data.subtitle && (
              <h3 className="title-w-img__subtitle">{data.subtitle}</h3>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
