import React from 'react'
import { Link } from 'gatsby'

export default function Card({ data }) {
  return (
    <article className="card">
      <div className="card__inner">
        <header className="card__header">
          <img src={data.img} alt={data.title} />
        </header>
        <div className="card__body">
          <h2 className="card__title">{data.title}</h2>
          <p className="card__description">{data.description}</p>
          <div className="card__btn">
            <Link className="btn btn-outline" to={data.button_link}>
              {data.button_text}
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}
