import React from 'react'
import { Link } from 'gatsby'

export default function Pricing({ data }) {
  return (
    <ul
      className={`pricing-table${
        data.suggested ? ' pricing-table--suggested' : ''
      }`}
    >
      <li className="pricing-table__title">
        {data.title}
        <small className="pricing-table__subtitle">{data.subtitle}</small>
      </li>
      <li className="pricing-table__price">
        <small className="pricing-table__price__sm">$</small>
        {`${data.price}`}
        <small className="pricing-table__price__sm">*</small>
      </li>
      {data.previous && (
        <li className="pricing-table__item">
          Includes everything with {data.previous}
        </li>
      )}
      {data.includes.map(item => (
        <li className="pricing-table__item">{item}</li>
      ))}
      <li className="pricing-table__btn">
        <Link
          className={`btn ${data.suggested ? 'btn-black' : 'btn-primary'}`}
          to={`/contact`}
        >
          Get {data.title}
        </Link>
        <em className="pricing-table__disclaimer">
          * Pricing may vary based on project needs.
        </em>
      </li>
    </ul>
  )
}
