import React from 'react'

export default function Project({ data }) {
  return (
    <div className="project">
      <a
        className="project__inner"
        href={data.url}
        target="_blank"
        rel="noreferrer nofollow"
      >
        <div className="project__img">
          <img src={data.hero} alt={data.title} />
        </div>
        <div className="project__overlay">
          <h2 className="project__title">{data.title}</h2>
          <p className="project__description">{data.description}</p>
          <ul className="project__tags">
            {data.tags.map(tag => (
              <li>{tag}</li>
            ))}
          </ul>
        </div>
      </a>
    </div>
  )
}
