// Gatsby supports TypeScript natively!
import React from 'react'
import { PageProps, Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TextImgSection from '../components/text-w-img-section'
import TitleImgSection from '../components/title-w-img-section'
import Card from '../components/card'
import Pricing from '../components/pricing'
import Project from '../components/project'

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const hero = {
    title: 'Design, develop and deliver.',
    subtitle:
      'Superfresh is a digital product studio based in sunny St. Pete, FL.',
    img: '/home/hero.jpg',
    page: 'Home',
  }

  const about = {
    align: 'left',
    title: 'Why Choose Us',
    subtitle: 'And what we can do for you',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus tellus eu eros eleifend, a sollicitudin purus mollis. Nullam fermentum dolor at urna pharetra, ut fermentum lacus ultricies. Nullam consectetur fringilla urna sit amet convallis. Aliquam lobortis ante sed lacus vulputate, eu fermentum libero luctus. Phasellus ipsum diam, hendrerit sed ipsum at, facilisis rutrum ante. Aenean imperdiet ex turpis, quis placerat enim pulvinar in.',
    button_text: 'Learn More',
    button_link: '/about',
    img: 'https://source.unsplash.com/o4UhdLv5jbQ',
  }

  const services_intro = {
    title: 'Services',
    subtitle: null,
    img: 'https://source.unsplash.com/qWwpHwip31M',
  }

  const services = [
    {
      title: 'Branding',
      description:
        'Logo design, print materials and digital branding services.',
      img: 'https://source.unsplash.com/r3iAqHb7JWs',
      button_text: 'Get In Touch',
      button_link: '/contact',
    },
    {
      title: 'Design',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus tellus eu eros eleifend, a sollicitudin purus mollis.',
      img: 'https://source.unsplash.com/gcsNOsPEXfs',
      button_text: 'Get In Touch',
      button_link: '/contact',
    },
    {
      title: 'Development',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus tellus eu eros eleifend, a sollicitudin purus mollis.',
      img: 'https://source.unsplash.com/pjAH2Ax4uWk',
      button_text: 'Get In Touch',
      button_link: '/contact',
    },
    {
      title: 'Hosting',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus tellus eu eros eleifend, a sollicitudin purus mollis.',
      img: 'https://source.unsplash.com/qTEj-KMMq_Q',
      button_text: 'Get In Touch',
      button_link: '/contact',
    },
  ]

  const pricing = [
    {
      title: 'Splash Starter',
      subtitle: 'Single Page',
      price: 1000,
      previous: null,
      includes: [
        'Performance Optimized',
        'SEO-ready',
        'ADA Compliant',
        'Contact Form',
      ],
      url: '#',
      suggested: false,
    },
    {
      title: 'Basic Starter',
      subtitle: '3 Page Static Site',
      price: 2500,
      previous: 'Splash Starter',
      includes: ['About Page', 'Contact Page', 'Newsletter Signup'],
      url: '#',
      suggested: false,
    },
    {
      title: 'Business Starter',
      subtitle: '5 Page Dynamic CMS',
      price: 3500,
      previous: 'Basic',
      includes: [
        'Additonal Content Pages',
        'User Management',
        'Upgraded Hosting',
      ],
      url: '#',
      suggested: false,
    },
    {
      title: 'Business Pro',
      subtitle: '12 Page Dynamic CMS',
      price: 5000,
      previous: 'Business Starter',
      includes: ['Blog Support', 'eCommerce Support', 'Top Tier Hosting'],
      url: '#',
      suggested: true,
    },
  ]

  const pricing_intro = {
    title: 'Packages',
    subtitle: null,
    img: 'https://source.unsplash.com/KGcLJwIYiac',
  }

  const pricing_more = {
    align: 'right',
    title: 'Need More?',
    subtitle: 'And what we can do for you',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus tellus eu eros eleifend, a sollicitudin purus mollis. Nullam fermentum dolor at urna pharetra, ut fermentum lacus ultricies. Nullam consectetur fringilla urna sit amet convallis. Aliquam lobortis ante sed lacus vulputate, eu fermentum libero luctus. Phasellus ipsum diam, hendrerit sed ipsum at, facilisis rutrum ante. Aenean imperdiet ex turpis, quis placerat enim pulvinar in.',
    button_text: 'Get In Touch',
    button_link: '/contact',
    img: 'https://source.unsplash.com/vGgn0xLdy8s',
  }

  const work_intro = {
    title: 'Our Work',
    subtitle: null,
    img: 'https://source.unsplash.com/JVSgcV8_vb4',
  }

  const projects = [
    {
      title: 'dinoFYI',
      description:
        'A dinosaur encyclopedia built with NextJS. Our contributions include branding, API devlopment/maitainance and user interface for this modern, interactive web app.',
      url: 'https://dino.fyi',
      hero: '/projects/dinoFYI/hero.jpg',
      thumbnail: '/projects/dinoFYI/thumbnail.jpg',
      tags: ['React', 'Next.js', 'Tailwindcss', 'CMS'],
    },
  ]

  return (
    <Layout location={location} title={siteTitle} hero={hero}>
      <SEO title="We Are Superfresh" />
      <section className="section about">
        <div className="container">
          <TextImgSection data={about} />
        </div>
      </section>
      <TitleImgSection data={services_intro} />
      <section className="section services">
        <div className="container">
          <div className="cards">
            {services.map((service, index) => (
              <Card data={service} key={index} />
            ))}
          </div>
        </div>
      </section>
      <TitleImgSection data={pricing_intro} />
      <section className="pricing">
        <div className="container">
          <div className="pricing-tables">
            {pricing.map((price, index) => (
              <Pricing data={price} key={index} />
            ))}
          </div>
        </div>
      </section>
      <section className="pb-12">
        <div className="container">
          <TextImgSection data={pricing_more} />
        </div>
      </section>
      <TitleImgSection data={work_intro} />
      <section className="pt-12 work">
        <div className="container">
          <div className="projects">
            {projects.map((project, index) => (
              <Project data={project} key={index} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
