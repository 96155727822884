import React from 'react'
import { Link } from 'gatsby'

export default function TextImgSection({ data }) {
  return (
    <div
      className={`text-w-img ${
        data.align === 'left' ? 'text-w-img--left' : 'text-w-img--right'
      }`}
    >
      <div className="text-w-img__inner">
        <div
          className={`text-w-img__col text-w-img__col--has-text ${
            data.align === 'left' ? 'order-first' : 'order-last'
          }`}
        >
          <h2 className="text-w-img__title">{data.title}</h2>
          <p className="text-w-img__body">{data.body}</p>
          <Link className="btn btn-secondary" to={data.button_link}>
            {data.button_text}
          </Link>
        </div>
        <div className="text-w-img__col text-w-img__col--has-img">
          <div className="text-w-img__col-img">
            <img src={data.img} alt={data.title} />
          </div>
        </div>
      </div>
    </div>
  )
}
